/* FIXES
--------------------------------------------------------------------------------------------*/

[data-s-type="hero"] .ac_hero_slide_content_text_title * {
    font-size: 40px;
    text-align: center;
    text-shadow: 1px 1px $brand-darkest-gray;

}

#home-hero-1{
    max-height:600px;
}

.ac_hero_slide_inner {
    max-height:600px;
}

[data-s-type="hero"] .ac_hero_container {
    max-height:600px;
}

@media only screen and (min-width: 900px ){
    [data-s-type="hero"] .ac_hero_slide_inner{
        margin-top: 15%;

        .column-title{
            font-size: 2rem !important;
        }
    }
}

[data-s-id="home-services"] {
    padding:0px;
}

[data-s-id="home-toptasks"] {
    margin-top:-140px;

    @media only screen and (max-width: 800px ){
        padding-bottom: 30px;

        .ac_toptasks_item:last-of-type{
            width: 100%;
            max-width: 100%;
        }
    }
}

.ac_toptasks_item_container {
    background: #fff;
}

.ac_toptasks_item:hover {
    .ac_toptasks_item_container {
    background: $brand-primary-darker;
    }
}

[data-m-type="menu-2"] a {
    color: $brand-darkest-gray;
}

.ac_menu-2_main_content a {
    font-size:20px;
    color: $brand-darkest-gray;
}

.ac_menu-2_bar_top {
    background-color: $brand-light-gray;
}

.ac_menu-2_bar_top a{
  
        color: $brand-black;

}

.ac_menu-2_bar_bottom{
    background: $brand-primary-darker;
    a{
        color: $brand-white;
        font-weight: bold;
    }
}

[data-m-type="menu-2"] .is-dropdown-submenu-item a {
   color:$brand-black;
    font-weight: normal;
}

[data-f-type="footer-1"] .ac_footer_primary {
    background-color: $brand-black;
}

body {
    color:$brand-black;
}

[data-f-type="footer-2"] {
    background-color: $brand-dark-gray;
}

[data-f-type="footer-2"] h2 {
    margin-bottom: -15px;
    margin-right:10px;
}

#home-services-5[data-s-type="services"] .ac_item_image-container:before{
    background: none;
    
}

#home-services-5[data-s-type="services"]{
    .ac_item_image-container {
        background: none;
        border: 1px solid #ffffff;
        border-radius: 0 0 0 50px;
    }

    .ac_item{ 
        margin: 0;
        padding: 0 7px 7px 0;

        @media only screen and (max-width: 800px){
            margin-bottom: 13px;
        }
    }

    
}

#home-services-5 .ac_item_image-container figure {
    display: none;
}


[data-s-type="hero"] .ac_hero_slide_content_inner{ 
    justify-content: flex-start;
    max-width: 65%;

    .ac_hero_slide_content_text_title *{
        text-align: left;
    }

}
/*
@media only screen and (max-width: 800px){
    .ac_menu-2_sticky{
        display: block;
    }
}*/

#gform_wrapper_5 {
    display: block !important;
}
input[type="radio" i] {
    padding:0 !important;
    margin:0 !important;
}

.gform_wrapper ul.gfield_checkbox li, .gform_wrapper ul.gfield_radio li {
    padding:0 !important;
    margin:0 !important;
    margin-right:10px !important;
    display:inline;
}

[data-s-type="hero"] .ac_hero_container:before {
    background: none !important;

}

.ac_menu-2_sticky.is-stuck .ac_menu-2_logo_img img {
    width: auto !important;
}


[data-s-id="home-toptasks"] {
    margin-top:-70px;
}

[data-s-type="services"] .ac_item {
    max-height: 180px;
}

[data-f-type="footer-2"] {
    background:$brand-tertiary;
}

.ac_content_1-1 {
    padding-left:10px;
    padding-right:10px;
}

.ac_menu-2_main_content a {
    color:$brand-black !important;
}

h2 {
    color:$brand-black;
}

.ac_item_image {
    opacity: 1 !important;
}

.ac_image-gallery_item_image_container {
    height:450px !important;
}


@media screen and (max-width: 1000px){
    .ac_image-gallery_item_image_container {
        height:350px !important;
    }
    
}

@media screen and (max-width: 800px){
    .ac_image-gallery_item_image_container {
        height:250px !important;
    }
    
}

@media screen and (max-width: 650px){
    .ac_image-gallery_item_image_container {
        height:180px !important;
    }
    
}

@media screen and (max-width: 49.9375em){
    .ac_menu-2_sticky{
        display: block;
    }

    .ac_menu-2_sticky.is-stuck .ac_menu-2_logo_img {
        width: auto !important;
    }

    #menu-mobile{
        list-style: none;
        width: 100%;
        margin-left: 0;
        padding-left: 0;
        li{
            display: block;
            width: 100%;
            text-align: center;
            margin: 0 0 5px 0;
            padding: 0 0 5px 0;
            border-bottom: 1px solid rgba(0,0,0,.1);
    
            a{
                text-align: center;
                display: block;
            }

            &:last-of-type{
                border-bottom: 0;
            }
        }

        .sub-menu{
            background: rgba(0,0,0,.1);
            padding: 10px 0;
            border-radius: 5px;
            li{
                border-bottom: 0;
            }
        }
    }
}

[data-s-type="aligntop"] .ac_image-gallery_item_image {
    background-position-y: top !important;
}

[data-f-type="footer-1"] .ac_footer_primary {
    background-color: #969db2 !important;
}

.ac_socials.v_smaller .ac_socials_link_icon {
    margin-top: -20px;
    margin-left: 10px;
}